<template>
  <section>
    <rj-email-signature :company="company" />
    <rj-email-domain-auth :company="company" />
  </section>
</template>

<script>
import RjEmailSignature from '@/views/common/email-settings/RjEmailSignature.vue';
import RjEmailDomainAuth from '@/views/common/email-settings/RjEmailDomainAuth.vue';

export default {
  name: 'RjEmailSettingsTab',

  components: { RjEmailSignature, RjEmailDomainAuth },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>
