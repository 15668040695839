<template>
  <section>
    <template v-if="loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <div class="vx-row">
        <div class="vx-col w-full sm:w-6/12 mb-4">
          <rj-partners-table :partners="partners" />
        </div>
        <div class="vx-col w-full sm:w-6/12 mb-4">
          <rj-companies-table :companies="companies" />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { httpBuildQuery } from '@/utils';
import {
  ELITE_PARTNER_PLAN, CERTIFIED_PARTNER_PLAN, HIGH_JUMP_CODE, LOW_JUMP_CODE,
} from '@/rj_consts';
import RjPartnersTable from '@/views/admin-dashboard/home/RjPartnersTable.vue';
import RjCompaniesTable from '@/views/admin-dashboard/home/RjCompaniesTable.vue';

export default {
  name: 'RjHome',

  components: {
    RjPartnersTable,
    RjCompaniesTable,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    partners() {
      return this.$store.getters['partners/partners'];
    },

    companies() {
      return this.$store.getters['companies/companies'];
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      await this.fetchPartners();
      await this.fetchCompanies();
      this.loading = false;
    },

    async fetchPartners() {
      await this.$store.dispatch('partners/getPartners', httpBuildQuery({
        filter: JSON.stringify({
          include: [
            {
              relation: 'partnerPlan',
              scope: {
                where: {
                  name: { inq: [ELITE_PARTNER_PLAN, CERTIFIED_PARTNER_PLAN] },
                },
              },
            },
            {
              relation: 'companies',
              scope: {
                include: [
                  {
                    relation: 'locations', scope: { where: { pending: false } },
                  },
                ],
              },
            },
          ],
        }),
      }));
    },

    async fetchCompanies() {
      await this.$store.dispatch('companies/getCompanies', httpBuildQuery({
        filter: JSON.stringify({
          include: [
            {
              relation: 'plan',
              scope: {
                where: {
                  code: { inq: [HIGH_JUMP_CODE, LOW_JUMP_CODE] },
                },
              },
            },
            {
              relation: 'locations',
              scope: { where: { pending: false } },
            },
          ],
        }),
      }));
    },
  },
};
</script>
