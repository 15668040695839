<template>
  <Main
    :vertical-nav-menu-items="verticalNavMenuItems"
    :horizontal-nav-menu-items="horizontalNavMenuItems"
  />
</template>
<script>
import Main from '@/layouts/main/Main.vue';
import Admin from '@/http/middleware/Admin';
import Auth from '@/http/middleware/Authenticate';
import AdminServiceProvider from '@/views/admin-dashboard/AdminServiceProvider';

export default {
  name: 'RjAdminDashboard',

  components: { Main },

  mixins: [AdminServiceProvider],

  middleware: [Auth, Admin],

  computed: {
    horizontalNavMenuItems() {
      return [
        {
          url: null, // Partner Success Kit
          icon: 'ZapIcon',
          color: 'rjPurple',
          title: 'Partner Success Kit',
        },
        {
          url: null,
          icon: 'LogOutIcon',
          color: 'rjPurple',
          title: 'Logout',
          action: this.logout,
        },
      ];
    },

    verticalNavMenuItems() {
      return [
        {
          url: '/admin',
          name: 'Home',
          slug: 'admin-home',
        },
        {
          url: '/admin/partners',
          name: 'Partners',
          slug: 'admin-partners',
        },
        {
          url: '/admin/companies',
          name: 'Companies',
          slug: 'admin-companies',
        },
        {
          url: '/admin/reports',
          name: 'Reports',
          slug: 'admin-reports',
        },
        {
          url: '/admin/settings',
          name: 'Settings',
          slug: 'admin-settings',
          submenu: [
            {
              url: null,
              name: 'Users',
              slug: 'users',
            },
            {
              url: null,
              name: 'Partner Success Kit',
              slug: 'partner-success-kit',
            },
            {
              url: null,
              name: 'Support FAQ',
              slug: 'support-faq',
            },
          ],
        },
      ];
    },
  },

  mounted() {
    this.$store.dispatch('roles/getRoles');
    this.$store.dispatch('revenuejump/getCategories');
    this.$store.dispatch('revenuejump/getReviewSites');
    this.$store.dispatch('revenuejump/getPlanFeatures');
  },

  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');

      return this.$router.replace({ name: 'login' });
    },
  },
};
</script>
