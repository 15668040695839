<template>
  <div>
    <line-chart
      :chart-data="data"
      :options="options"
      style="height: 250px;"
    />
  </div>
</template>

<script>
import { format } from 'date-fns';
import { colors as themeColors } from '@/../themeConfig';
import LineChart from './LineChart';

export default {
  components: {
    LineChart,
  },
  props: {
    days: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          align: 'start',
          padding: 50,
          labels: {
            usePointStyle: true,
            font: {
              family: 'Montserrat',
              size: 8,
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                callback: (val) => (val % 1 === 0 ? val : ''),
                min: 0,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    };
  },
  async mounted() {
    this.buildDataSets();
  },
  methods: {
    async buildDataSets() {
      const labels = this.days.map((day) => format(day.date, 'EEE MM/d'));
      const data = {
        labels,
        datasets: [],
      };
      const notificationsDataSet = {
        label: 'Notifications Sent',
        data: [],
        borderColor: themeColors.rjOrange,
        backgroundColor: themeColors.rjOrange,
        fill: false,
        pointStyle: 'line',
      };
      for (const day of this.days) {
        notificationsDataSet.data.push(day.notificationsCount);
      }
      // this.options.scales.yAxes[0].ticks.min = Math.min(...surveysDataSet.data, ...smsDataSet.data, ...emailDataSet.data);
      // this.options.scales.yAxes[0].ticks.max = Math.max(...surveysDataSet.data, ...smsDataSet.data, ...emailDataSet.data);
      data.datasets.push(notificationsDataSet);
      this.data = data;
    },
  },
};
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
