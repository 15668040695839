<template>
  <section>
    <template v-if="loading">
      <p>Loading Location...</p>
    </template>

    <template v-else>
      <header class="flex flex-wrap items-center mb-6">
        <h2 class="pr-3 font-semibold text-gray-600 mr-3 border-0 md:border-r border-solid border-gray-500">
          {{ company.name }}
        </h2>
        <rj-breadcrumb :items="breadcrumbs" />
      </header>

      <rj-location-statistics />

      <div class="flex flex-wrap -mx-4">
        <div class="w-full sm:w-1/2 px-4 mb-4">
          <rj-location-form
            :location="location"
            :funnels="funnels"
            :timezones="timezones"
            @should-upgrade="upgrade"
          />
        </div>

        <div class="w-full sm:w-1/2 px-4">
          <rj-review-sites
            :location="location"
            :location-review-sites="locationReviewSites"
          />

          <rj-competitors :location="location" />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { httpBuildQuery, bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import RjReviewSites from '@/views/common/locations/partials/RjReviewSites.vue';
import RjCompetitors from '@/views/common/locations/partials/RjCompetitors.vue';
import RjLocationForm from '@/views/common/locations/partials/RjLocationForm.vue';
import RjLocationStatistics from '@/views/common/locations/partials/RjLocationStatistics.vue';

export default {
  name: 'RjLocationSettings',

  components: {
    RjCompetitors,
    RjReviewSites,
    RjLocationForm,
    RjLocationStatistics,
  },

  mixins: [Authorizable],

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    company() {
      return this.$store.getters['companies/company'];
    },

    location() {
      return this.$store.getters['locations/location'];
    },

    funnels() {
      return this.$store.getters['funnels/funnels'];
    },

    locationReviewSites() {
      const reviewSites = this.$store.getters['locations/reviewSites'].filter((s) => s.active);

      return [...reviewSites].sort((curr, next) => next.reviewCount - curr.reviewCount);
    },

    timezones() {
      return this.$store.getters['revenuejump/timezones'];
    },

    breadcrumbs() {
      return [
        {
          title: 'Companies',
          url: '/admin/companies',
        },
        {
          title: this.company.name,
          url: `/admin/companies/${this.company.id}/general`,
        },
        {
          title: 'Locations',
          url: `/admin/companies/${this.company.id}/locations`,
        },
        {
          title: this.location.name,
          active: true,
        },
      ];
    },
  },

  watch: {
    '$route.params.location': {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    /**
     * Fetch all the data needed for location
     *
     * @return {Promise}
     */
    async fetch() {
      const locationId = parseInt(this.$route.params.location, 10);

      const location = await this.fetchLocation(locationId);
      await this.fetchFunnels(location.companyId);
      await this.$store.dispatch('locations/fetchReviewSitesAnalytics', locationId);
      await this.$store.dispatch('locations/fetchCompetitors', locationId);
      await this.$store.dispatch('revenuejump/getTimezones');

      this.loading = false;
    },

    /**
     * Fetch the location details with its connected review sites
     *
     * @param  {Integer} locationId
     * @return {Promise}
     */
    async fetchLocation(locationId) {
      const filters = httpBuildQuery({
        includeSurveyStats: 1,
        filter: JSON.stringify({
          where: { active: true },
          include: [
            {
              relation: 'locationReviewSites',
              scope: { include: ['reviewSite'] },
            },
          ],
        }),
      });

      return this.$store.dispatch('locations/fetchLocation', { locationId, filters });
    },

    async fetchFunnels(companyId) {
      const types = ['Testimonial'];
      if (this.guard_company_plan_allows('SURVEY_TYPES', 1)) {
        types.push('Review', 'Referral');
      }
      await this.$store.dispatch('funnels/fetchFunnels', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { companyId, active: true, type: { inq: types } },
          fields: ['id', 'name', 'locationId', 'type'],
        }),
      }));
    },

    upgrade() {
      bus.$emit('upgrade-company-intent', this.company);
    },
  },
};
</script>
