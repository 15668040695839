<template>
  <vx-card title="Companies">
    <template slot="no-body">
      <div class="pt-6 mx-6">
        <vs-table
          search
          pagination
          class="table-fixed"
          :data="companies"
          :max-items="itemsPerPage"
          :no-data-text="'No Companies'"
          @selected="jumpToCompany"
        >
          <template slot="header">
            <div class="flex flex-wrap-reverse items-center flex-grow justify-between">
              <!-- ADD NEW BUTTON -->
              <div class="mt-auto">
                <vs-button
                  color="primary"
                  class="w-full"
                  @click="addCompany"
                >
                  Add Company
                </vs-button>
              </div>
            </div>
          </template>

          <template slot="thead">
            <vs-th
              sort-key="company"
              class="w-7/12"
            >
              COMPANY
            </vs-th>
            <vs-th
              sort-key="locations"
              class="w-1/12"
            >
              <span class="w-full text-center">LOCATIONS</span>
            </vs-th>
            <vs-th
              sort-key="plan"
              class="w-2/12"
            >
              <span class="w-full text-center">PLAN</span>
            </vs-th>
            <vs-th class="w-2/12">
              <span class="w-full text-center">ACTION</span>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr
                v-for="(company, index) in data"
                :key="index"
                :data="company"
              >
                <vs-td
                  class="w-7/12 whitespace-no-wrap overflow-hidden"
                  style="max-width: 0.063rem;"
                >
                  <p class="font-medium truncate">
                    {{ company.name }}
                  </p>
                </vs-td>

                <vs-td
                  class="w-1/12 whitespace-no-wrap overflow-hidden"
                  style="max-width: 0.063rem;"
                >
                  <p class="font-medium truncate text-center">
                    {{ company.locations.length }}
                  </p>
                </vs-td>

                <vs-td class="w-2/12 whitespace-no-wrap">
                  <p class="font-medium text-center">
                    {{ company.plan.name }}
                  </p>
                </vs-td>

                <vs-td class="w-2/12 whitespace-no-wrap">
                  <div class="flex flex-no-wrap">
                    <vs-button
                      icon-pack="feather"
                      icon="icon-settings"
                      type="flat"
                      class="inline-block"
                      title="Company Settings"
                      @click.stop="jumpToCompany(company)"
                    />
                    <vs-button
                      icon-pack="feather"
                      icon="icon-external-link"
                      type="flat"
                      class="inline-block"
                      title="Open Company Dashboard"
                      @click.stop="openCompanyDashboard(company)"
                    />
                    <!-- Only if plan is not High Jump -->
                    <vs-button
                      v-if="company.plan.code !== HIGH_JUMP_CODE"
                      icon-pack="feather"
                      icon="icon-arrow-up"
                      type="flat"
                      class="inline-block"
                      title="Upgrade Plan"
                      @click.stop="openPlanUpgradeDialog(company)"
                    />
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </template>
  </vx-card>
</template>

<script>
import * as codes from '@/rj_consts';

export default {
  name: 'RjCompaniesTable',

  props: {
    companies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      itemsPerPage: 5,
      HIGH_JUMP_CODE: codes.HIGH_JUMP_CODE,
    };
  },

  methods: {
    addCompany() {
      // code...
    },

    jumpToCompany(company) {
      this.$router.push({
        name: 'admin-company',
        params: { company: company.id, slug: 'general' },
      });
    },

    openCompanyDashboard(company) {
      localStorage.setItem('actAsCompanyId', company.id);

      const route = this.$router.resolve({
        name: 'company-home',
      });
      return window.open(route.href, '_blank');
    },

  },
};
</script>
