<template>
  <div>
    <line-chart
      :chart-data="data"
      :options="options"
      style="height: 250px;"
    />
  </div>
</template>

<script>
import {
  differenceInDays, format, parseISO, sub,
} from 'date-fns';
import { colors as themeColors } from '@/../themeConfig';
import LineChart from './LineChart';

export default {
  components: {
    LineChart,
  },
  props: {
    integrationErrors: {
      type: Object,
      default: () => ({
        cloudBridgeErrors: [],
        googleErrors: [],
        facebookErrors: [],
        vetdataErrors: [],
        cloud9orthoErrors: [],
      }),
    },
  },
  data() {
    return {
      data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          align: 'start',
          padding: 50,
          labels: {
            usePointStyle: true,
            font: {
              family: 'Montserrat',
              size: 8,
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                callback: (val) => (val % 1 === 0 ? val : ''),
                min: 0,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    };
  },
  async mounted() {
    this.buildDataSets();
  },
  methods: {
    async buildDataSets() {
      const today = new Date();
      const days = [];
      for (let i = 6; i > -1; i--) {
        days.push(sub(today, { days: i }));
      }
      const labels = days.map((day) => format(day, 'EEE MM/d'));
      const data = {
        labels,
        datasets: [],
      };

      // Google My Business errors
      const googleDataSet = {
        label: 'Google Errors',
        data: [],
        borderColor: themeColors.rjGreen,
        backgroundColor: themeColors.rjGreen,
        fill: false,
        pointStyle: 'line',
      };
      const googleData = [0, 0, 0, 0, 0, 0, 0];
      for (const error of this.integrationErrors.googleErrors) {
        const timestamp = parseISO(error.timestamp);
        const daysDiff = 6 - differenceInDays(today, timestamp);
        if (googleData[daysDiff]) {
          googleData[daysDiff]++;
        } else {
          googleData[daysDiff] = 1;
        }
      }
      googleDataSet.data = googleData;
      data.datasets.push(googleDataSet);

      // Facebook errors
      const facebookDataSet = {
        label: 'Facebook Errors',
        data: [],
        borderColor: themeColors.rjBlue,
        backgroundColor: themeColors.rjBlue,
        fill: false,
        pointStyle: 'line',
      };
      const facebookData = [0, 0, 0, 0, 0, 0, 0];
      for (const error of this.integrationErrors.facebookErrors) {
        const timestamp = parseISO(error.timestamp);
        const daysDiff = 6 - differenceInDays(today, timestamp);
        if (facebookData[daysDiff]) {
          facebookData[daysDiff]++;
        } else {
          facebookData[daysDiff] = 1;
        }
      }
      facebookDataSet.data = facebookData;
      data.datasets.push(facebookDataSet);

      // rj-cloud-bridge infusionsoft errors
      const infusionsoftDataSet = {
        label: 'Infusionsoft Errors',
        data: [],
        borderColor: themeColors.rjOrange,
        backgroundColor: themeColors.rjOrange,
        fill: false,
        pointStyle: 'line',
      };
      const infusionsoftData = [0, 0, 0, 0, 0, 0, 0];
      for (const error of this.integrationErrors.infusionsoftErrors) {
        const timestamp = parseISO(error.timestamp);
        const daysDiff = 6 - differenceInDays(today, timestamp);
        if (infusionsoftData[daysDiff]) {
          infusionsoftData[daysDiff]++;
        } else {
          infusionsoftData[daysDiff] = 1;
        }
      }
      infusionsoftDataSet.data = infusionsoftData;
      data.datasets.push(infusionsoftDataSet);

      // rj-cloud-bridge cloud9ortho errors
      const cloud9orthoDataSet = {
        label: 'Cloud9Ortho Errors',
        data: [],
        borderColor: themeColors.rjYellow,
        backgroundColor: themeColors.rjYellow,
        fill: false,
        pointStyle: 'line',
      };
      const cloud9orthoData = [0, 0, 0, 0, 0, 0, 0];
      for (const error of this.integrationErrors.cloud9orthoErrors) {
        const timestamp = parseISO(error.timestamp);
        const daysDiff = 6 - differenceInDays(today, timestamp);
        if (cloud9orthoData[daysDiff]) {
          cloud9orthoData[daysDiff]++;
        } else {
          cloud9orthoData[daysDiff] = 1;
        }
      }
      cloud9orthoDataSet.data = cloud9orthoData;
      data.datasets.push(cloud9orthoDataSet);

      // rj-cloud-bridge vetdata errors
      const vetdataDataSet = {
        label: 'VetData Errors',
        data: [],
        borderColor: themeColors.rjRed,
        backgroundColor: themeColors.rjRed,
        fill: false,
        pointStyle: 'line',
      };
      const vetdataData = [0, 0, 0, 0, 0, 0, 0];
      for (const error of this.integrationErrors.vetdataErrors) {
        const timestamp = parseISO(error.timestamp);
        const daysDiff = 6 - differenceInDays(today, timestamp);
        if (vetdataData[daysDiff]) {
          vetdataData[daysDiff]++;
        } else {
          vetdataData[daysDiff] = 1;
        }
      }
      vetdataDataSet.data = vetdataData;
      data.datasets.push(vetdataDataSet);

      this.data = data;
    },
  },
};
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
