<template>
  <section>
    <template v-if="loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <rj-companies-analytics :companies="companies" />

      <div class="mt-4">
        <vx-card title="Companies">
          <rj-companies-list
            :companies="filteredCompanies"
            @selected="openCompanyGeneral"
          >
            <template #actions>
              <div class="flex items-center mt-auto p-3">
                <div
                  v-if="hasArchivedCompanies"
                  class="flex"
                >
                  <vs-radio
                    v-model="filter.name"
                    vs-value="active"
                  >
                    Active
                  </vs-radio>
                  <vs-radio
                    v-model="filter.name"
                    vs-value="archived"
                    class="ml-3"
                  >
                    Archived
                  </vs-radio>

                  <vs-button
                    v-if="filter.name === 'archived' && !filter.applied"
                    type="border"
                    icon-pack="feather"
                    icon="icon-refresh-cw"
                    size="small"
                    class="ml-3"
                    @click="filter.applied = true"
                  />
                </div>
              </div>
            </template>

            <template #table-head>
              <vs-th>
                COMPANY
              </vs-th>
              <vs-th>
                CONTACT
              </vs-th>
              <vs-th>
                <span class="text-center">LOCATIONS</span>
              </vs-th>
              <vs-th v-if="filter.name === 'active'">
                <span class="w-full text-center">30-DAY SURVEYS</span>
              </vs-th>
              <vs-th v-if="filter.name === 'active'">
                <span class="w-full text-center">PLAN</span>
              </vs-th>
              <vs-th>
                <span class="w-full text-center">PARTNER</span>
              </vs-th>
              <vs-th>
                <span class="w-full text-center">ACTION</span>
              </vs-th>
            </template>

            <template #table-row="{ company }">
              <vs-td>
                <p class="font-medium truncate">
                  {{ company.name.length > 40 ? `${ company.name.slice(0, 40) }...` : company.name }}
                </p>
              </vs-td>
              <vs-td>
                <p class="font-medium truncate">
                  {{ company.mainContact ? `${company.mainContact.firstName} ${company.mainContact.lastName}` : 'No contact found' }}
                </p>
              </vs-td>
              <vs-td>
                <p class="font-medium truncate text-center">
                  {{ company.locations.length }}
                </p>
              </vs-td>
              <vs-td v-if="filter.name === 'active'">
                <p class="font-medium truncate text-center">
                  {{ company.num30DaySurveys }}
                </p>
              </vs-td>
              <vs-td>
                <p class="font-medium truncate text-center">
                  {{ company.plan.name }}
                </p>
              </vs-td>
              <vs-td v-if="filter.name === 'active'">
                <p class="font-medium truncate text-center">
                  {{ company.partner.name.length > 14 ? `${company.partner.name.slice(0, 14)}...` : company.partner.name }}
                </p>
              </vs-td>
              <vs-td>
                <div
                  v-if="filter.name === 'active'"
                  class="flex flex-no-wrap"
                >
                  <vs-button
                    icon-pack="feather"
                    icon="icon-settings"
                    type="flat"
                    class="inline-block"
                    title="Company Settings"
                    :color="themeColors.rjPurple"
                    @click.stop="openCompanyGeneral(company)"
                  />
                  <vs-button
                    icon-pack="feather"
                    icon="icon-external-link"
                    type="flat"
                    class="inline-block"
                    title="Open Company Dashboard"
                    :color="themeColors.rjPurple"
                    @click.stop="openCompanyDashboard(company)"
                  />
                  <!-- Only if plan is not High Jump -->
                  <vs-button
                    v-if="company.plan.code !== HIGH_JUMP_CODE"
                    icon-pack="feather"
                    icon="icon-arrow-up"
                    type="flat"
                    class="inline-block"
                    title="Upgrade Plan"
                    :color="themeColors.rjPurple"
                    @click.stop="openPlanUpgradeDialog(company)"
                  />
                </div>

                <div
                  v-if="hasFilters"
                  class="flex flex-no-wrap"
                >
                  <vs-button
                    icon-pack="feather"
                    icon="icon-rotate-ccw"
                    type="flat"
                    class="inline-block"
                    title="Reactivate Account"
                    :color="themeColors.rjPurple"
                    @click.stop="openRestoreDialog(company)"
                  />
                </div>
              </vs-td>
            </template>
          </rj-companies-list>
        </vx-card>
      </div>
    </template>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { HIGH_JUMP_CODE, LOW_JUMP_CODE } from '@/rj_consts';
import { httpBuildQuery } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import { colors as themeColors } from '@/../themeConfig';
import RjCompaniesList from '@/views/common/companies/RjCompaniesList.vue';
import RjCompaniesAnalytics from '@/views/common/companies/RjCompaniesAnalytics.vue';

export default {
  name: 'RjCompanies',

  components: {
    RjCompaniesList,
    RjCompaniesAnalytics,
  },

  mixins: [Authorizable],

  data() {
    return {
      themeColors,
      loading: true,
      LOW_JUMP_CODE,
      HIGH_JUMP_CODE,
      filter: { name: 'active', applied: true },
    };
  },

  computed: {
    companies() {
      return this.$store.getters['companies/companies'];
    },

    archived() {
      return this.$store.getters['companies/archivedCompanies'];
    },

    hasFilters() {
      return this.filter.name === 'archived' && this.filter.applied;
    },

    filteredCompanies() {
      return this.hasFilters ? this.archived : this.companies;
    },

    hasArchivedCompanies() {
      return !!this.archived.length;
    },
  },

  watch: {
    $router: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      await this.fetchActiveCompanies();
      await this.fetchArchivedCompanies();
      this.loading = false;
    },

    async fetchActiveCompanies() {
      await this.$store.dispatch('companies/getCompanies', httpBuildQuery({
        filter: JSON.stringify({
          include: [
            'partner',
            'mainContact',
            {
              relation: 'plan',
              scope: {
                where: {
                  code: { inq: [HIGH_JUMP_CODE, LOW_JUMP_CODE] },
                },
              },
            },
            {
              relation: 'locations',
              scope: { where: { pending: false } },
            },
          ],
        }),
      }));
    },

    async fetchArchivedCompanies() {
      await this.$store.dispatch('companies/getArchived', httpBuildQuery({
        filter: JSON.stringify({
          where: { active: false },
          include: [
            'partner',
            'mainContact',
            {
              relation: 'plan',
              scope: {
                where: {
                  code: { inq: [HIGH_JUMP_CODE, LOW_JUMP_CODE] },
                },
              },
            },
            {
              relation: 'mainContact',
              scope: { where: { active: false } },
            },
            {
              relation: 'locations',
              scope: { where: { active: false } },
            },
          ],
        }),
      }));
    },

    /**
     * Navigates to the given company page.
     *
     * @param  {Object} company
     * @return {void}
     */
    openCompanyGeneral(company) {
      if (company.active) {
        this.$router.push({
          name: 'admin-company',
          params: { company: company.id, slug: 'general' },
        });
      }
    },

    /**
     * Opens a company dashboard in a new window
     *
     * @param  {Object} company
     * @return {mixed}
     */
    openCompanyDashboard(company) {
      localStorage.setItem('actAsCompanyId', company.id);

      const route = this.$router.resolve({
        name: 'company-home',
      });
      return window.open(route.href, '_blank');
    },


    /**
     * Opens an alert dialog to confirm the restore company action.
     *
     * @param  {Object} company
     * @return {void}
     */
    async openRestoreDialog(company) {
      const { amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');

      const highJumpLocations = company.locations.filter((location) => location.plan.code === HIGH_JUMP_CODE);
      const proratedAmount = this.formatCentsAsDollars(highJumpLocations.length * amount);

      const body = {
        [LOW_JUMP_CODE]: 'This account will be restored as it was before on the Low Jump plan. \n Please note, you will need reconnect review sites and integrations, add users, customize templates, and make other manual changes to the settings in order to restore full functionality.',
        [HIGH_JUMP_CODE]: `This account will be restored as it was before on the High Jump plan. You will be charged a prorated amount of ${proratedAmount} now and $99.00/mo USD for this account beginning on the next ${format(renewalDate, 'do')} of the month. \n Please note, you will need to reconnect review sites and integrations, add users, customize templates, and make other manual changes to the settings in order to restore full functionality.`,
      };

      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Please Confirm',
        text: body[company.plan.code],
        acceptText: 'Accept',
        accept: () => this.restore(company),
      });
    },

    /**
     * Restores a given company.
     *
     * @param  {Object} company
     * @return {void}
     */
    async restore(company) {
      await this.$store.dispatch('companies/unarchive', company.id);
      const error = this.$store.getters['companies/error'];
      if (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      } else {
        this.$vs.notify({
          title: 'Success',
          text: `${company.name} was unarchived.`,
          color: 'success',
        });

        this.openCompanyGeneral(company);
      }
    },
  },
};
</script>
