<template>
  <div v-if="!loading">
    <router-view v-bind="{ company, plan, locations, partner }" />
  </div>
</template>

<script>
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjBaseCompanyView',

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    company() {
      return this.$store.getters['companies/company'];
    },

    plan() {
      return this.$store.getters['companies/plan'];
    },

    locations() {
      return this.$store.getters['locations/locations'];
    },

    partner() {
      return this.$store.getters['partners/partner'];
    },
  },

  watch: {
    '$route.params.company': {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch(companyId) {
      const filters = httpBuildQuery({
        filter: JSON.stringify({
          include: [
            'plan',
            {
              relation: 'companyPlans',
              scope: {
                where: {
                  active: true,
                },
                include: ['plan'],
              },
            },
            {
              relation: 'locations',
              scope: { where: { pending: false } },
            },
          ],
        }),
      });

      await this.$store.dispatch('companies/getCompany', { companyId, filters });

      if (!this.partner) {
        const { partnerId } = this.company;
        await this.$store.dispatch('partners/getPartner', { partnerId });
      }

      this.loading = false;
    },
  },
};
</script>
