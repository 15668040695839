<template>
  <div v-if="!loading">
    <router-view v-bind="{ partner }" />
  </div>
</template>

<script>
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjBasePartnerView',

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    partner() {
      return this.$store.getters['partners/partner'];
    },
  },

  watch: {
    '$route.params.partner': {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch(partnerId) {
      const filters = httpBuildQuery({
        filter: JSON.stringify({
          include: ['partnerPlan'],
        }),
      });
      await this.$store.dispatch('partners/getPartner', { partnerId, filters });

      this.loading = false;
    },
  },
};
</script>
