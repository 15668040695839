<template>
  <section>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600 mr-3 border-0 md:border-r border-solid border-gray-500">
        {{ company.name }}
      </h2>
      <rj-breadcrumb :items="breadcrumbs" />
    </header>

    <vs-tabs
      :value="currentTab"
      :position="isSmallerScreen ? 'top' : 'left'"
      class="tabs-shadow-none"
    >
      <vs-tab
        v-bind="tabProps({ slug: 'general', icon: 'icon-box' })"
        @click="navigate('general')"
      >
        <rj-general-tab v-bind="{ company, locations }" />
      </vs-tab>

      <vs-tab
        v-bind="tabProps({ slug: 'locations', icon: 'icon-map-pin' })"
        @click="navigate('locations')"
      >
        <rj-locations-tab v-bind="{ company, plan }" />
      </vs-tab>

      <vs-tab
        v-bind="tabProps({ slug: 'users', icon: 'icon-users' })"
        @click="navigate('users')"
      >
        <rj-users-tab v-bind="{ company }" />
      </vs-tab>

      <vs-tab
        v-bind="tabProps({ slug: 'funnels', icon: 'icon-file-text' })"
        @click="navigate('funnels')"
      >
        <rj-funnels-tab v-bind="{ company }" />
      </vs-tab>

      <vs-tab
        v-bind="tabProps({ slug: 'email-settings', icon: 'icon-at-sign' })"
        @click="navigate('email-settings')"
      >
        <rj-email-settings-tab v-bind="{ company }" />
      </vs-tab>
    </vs-tabs>
  </section>
</template>

<script>
import RjUsersTab from '@/views/admin-dashboard/companies/tabs/RjUsersTab.vue';
import RjGeneralTab from '@/views/admin-dashboard/companies/tabs/RjGeneralTab.vue';
import RjFunnelsTab from '@/views/admin-dashboard/companies/tabs/RjFunnelsTab.vue';
import RjLocationsTab from '@/views/admin-dashboard/companies/tabs/RjLocationsTab.vue';
import RjEmailSettingsTab from '@/views/admin-dashboard/companies/tabs/RjEmailSettingsTab.vue';

export default {
  name: 'RjCompany',

  components: {
    RjUsersTab,
    RjFunnelsTab,
    RjGeneralTab,
    RjLocationsTab,
    RjEmailSettingsTab,
  },

  props: {
    slug: {
      type: [String, null],
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },

    plan: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    partner: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tabs: {
        'general': 'General',
        'locations': 'Locations',
        'users': 'Users',
        'funnels': 'Funnels',
        'email-settings': 'Email Settings',
      },
    };
  },

  computed: {
    title() {
      return this.tabs[this.slug];
    },

    currentTab() {
      return Object.keys(this.tabs).indexOf(this.slug);
    },

    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },

    breadcrumbs() {
      const { id, name } = this.company;

      return [
        { title: 'Companies', url: '/admin/companies' },
        { title: name, url: `/admin/companies/${id}/general` },
        { title: this.title, active: true },
      ];
    },
  },

  mounted() {
    this.navigate(this.slug);
  },

  methods: {
    tabProps({ slug, icon }) {
      return {
        icon,
        iconPack: 'feather',
        label: !this.isSmallerScreen ? this.tabs[slug] : '',
      };
    },

    navigate(slug) {
      if (this.slug !== slug) {
        this.$router.push({ name: 'admin-company', params: { slug } });
      }
    },
  },
};
</script>
