<template>
  <div>
    <line-chart
      :chart-data="data"
      :options="options"
      style="height: 250px;"
    />
  </div>
</template>

<script>
import {
  differenceInDays, format, parseISO, sub,
} from 'date-fns';
import { colors as themeColors } from '@/../themeConfig';
import LineChart from './LineChart';

export default {
  components: {
    LineChart,
  },
  props: {
    rjApiErrors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          align: 'start',
          padding: 50,
          labels: {
            usePointStyle: true,
            font: {
              family: 'Montserrat',
              size: 8,
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                callback: (val) => (val % 1 === 0 ? val : ''),
                min: 0,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    };
  },
  async mounted() {
    this.buildDataSets();
  },
  methods: {
    async buildDataSets() {
      const today = new Date();
      const days = [];
      for (let i = 6; i > -1; i--) {
        days.push(sub(today, { days: i }));
      }
      const labels = days.map((day) => format(day, 'EEE MM/d'));
      const data = {
        labels,
        datasets: [],
      };

      // rj-api errors
      const errorDataSet = {
        label: 'API Errors',
        data: [],
        borderColor: themeColors.rjRed,
        backgroundColor: themeColors.rjRed,
        fill: false,
        pointStyle: 'line',
      };
      const errorData = [0, 0, 0, 0, 0, 0, 0];
      for (const error of this.rjApiErrors) {
        const timestamp = parseISO(error.timestamp);
        const daysDiff = 6 - differenceInDays(today, timestamp);
        if (errorData[daysDiff]) {
          errorData[daysDiff]++;
        } else {
          errorData[daysDiff] = 1;
        }
      }
      errorDataSet.data = errorData;
      data.datasets.push(errorDataSet);

      this.data = data;
    },
  },
};
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
