<template>
  <section>
    <rj-company-analytics v-bind="{ grades, crs, surveysSent, satisfactionScore }" />

    <div class="vx-row">
      <div class="vx-col w-full lg:w-8/12">
        <rj-company-form
          :company="company"
          class="mt-8"
        />
      </div>

      <div class="vx-col w-full lg:w-4/12">
        <rj-company-overview
          :company="company"
          class="my-8"
        />
        <rj-company-logo :company="company" />
      </div>
    </div>
  </section>
</template>

<script>
import sub from 'date-fns/sub';
import RjCompanyForm from '@/views/common/companies/RjCompanyForm.vue';
import RjCompanyLogo from '@/views/common/companies/RjCompanyLogo.vue';
import RjCompanyOverview from '@/views/common/companies/RjCompanyOverview.vue';
import RjCompanyAnalytics from '@/views/common/companies/RjCompanyAnalytics.vue';

export default {
  name: 'RjGeneralTab',

  components: {
    RjCompanyLogo,
    RjCompanyForm,
    RjCompanyOverview,
    RjCompanyAnalytics,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      surveysSent: 0,
      crs: { scores: [] },
      satisfactionScore: 0,
      grades: [{ date: new Date(), rpg: 0 }],
    };
  },

  mounted() {
    this.fetchSurveysSent();
    this.fetchSatisfactionScore();
    this.fetchReputationPerformanceGrade();
    this.fetchCompetitorReviewScoreHistory();

    this.$store.dispatch('companies/withCounts', this.company.id);
    this.$store.dispatch('companies/fetchSmsStatus', this.company.id);
  },

  methods: {
    async fetchReputationPerformanceGrade() {
      const locationIds = this.locations.map((l) => l.id);
      const filter = {
        where: { locationId: { inq: locationIds } },
      };

      this.grades = await this.$store.dispatch('companies/getReputationPerformanceGrade', filter);
    },

    async fetchCompetitorReviewScoreHistory() {
      this.crs = await this.$store.dispatch('companies/getCompetitorReviewScoreHistory', this.company.id);
    },

    async fetchSatisfactionScore() {
      const locationIds = this.locations.map((l) => l.id);
      const filter = {
        where: { locationId: { inq: locationIds } },
      };

      const { count } = await this.$store.dispatch('companies/getSatisfactionScore', filter);
      this.satisfactionScore = count ? Math.round(count) : 0;
    },

    async fetchSurveysSent() {
      const thirtyDaysAgo = sub(new Date(), { days: 30 }).toISOString();
      const filter = {
        where: {
          companyId: this.$route.params.company,
          createdAt: { gte: thirtyDaysAgo },
        },
      };

      const { count } = await this.$store.dispatch('companies/getSurveysSent', filter);
      this.surveysSent = count;
    },
  },
};
</script>
