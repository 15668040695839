import { format } from 'date-fns';
import { bus } from '@/utils';
import * as codes from '@/rj_consts';

const events = [
  { name: 'show-modal', handler: '$_events_openModal' },
  { name: 'hide-modal', handler: '$_events_closeModal' },
  { name: 'archive-company', handler: '$_events_archiveCompany' },
  { name: 'upgrade-company-intent', handler: '$_events_upgradeCompanyIntent' },
  { name: 'upgrade-company', handler: '$_events_upgradeCompanyPlan' },
  { name: 'elite-upgrade-cta', handler: '$_events_upgradeEliteAlert' },
  { name: 'downgrade-company', handler: '$_events_downgradeCompanyPlan' },
];

export default {
  created() {
    for (const { name, handler } of events) {
      bus.$on(name, this[handler]);
    }
  },

  beforeDestroy() {
    for (const { name, handler } of events) {
      bus.$off(name, this[handler]);
    }
  },

  methods: {
    $_events_openModal() {
      //
    },

    $_events_closeModal() {
      //
    },

    async $_events_archiveCompany(company) {
      const { renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
      const body = {
        [codes.LOW_JUMP_CODE]: 'The Company will lose access to their dashboard. While their survey history will be archived, their user accounts, survey templates, and other data will be deleted.',
        [codes.HIGH_JUMP_CODE]: `This Company is paid through ${format(renewalDate, 'MMM. do')}. By archiving it, the Partner will not receive a refund, prorated or otherwise. They will also lose the commissions they would be earning on this account. \n\n Additionally, the Company will lose access to their dashboard. While their survey history will be archived, their user accounts, survey templates, and other data will be deleted.`,
      };

      return this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Are you sure?',
        text: body[company.plan.code],
        acceptText: 'Accept',
        accept: async() => {
          this.$vs.notify({
            title: 'Archiving company',
            text: 'Please wait..',
            color: 'success',
            time: 4000,
          });
          await this.$store.dispatch('companies/archive', company.id);
          const error = this.$store.getters['companies/error'];
          if (error) {
            this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
          } else {
            this.$vs.notify({
              title: 'Success',
              text: `${company.name} has been archived.`,
              color: 'success',
            });
            this.$router.replace({ name: 'admin-companies' });
          }
        },
      });
    },

    $_events_upgradeCompanyIntent() {
      // eslint-disable-next-line
      alert('WIP - Admin Wants to upgrade a company');
    },

    $_events_upgradeCompanyPlan() {
      // eslint-disable-next-line
      alert('WIP - Admin Upgrades Company');
    },

    $_events_upgradeEliteAlert() {
      // eslint-disable-next-line
      alert('WIP - Admin Upgrade Partner to Elite');
    },

    $_events_downgradeCompanyPlan() {
      // eslint-disable-next-line
      alert('WIP - Admin Downgrade Company Plan');
    },
  },
};
