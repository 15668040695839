<template>
  <section>
    <vx-card
      title="Surveys Sent"
      class="mb-8"
    >
      <rj-surveys-sent-chart
        v-if="surveysLoaded"
        :days="surveysByDay"
      />
      <div v-else>
        Loading...
      </div>
    </vx-card>

    <vx-card
      title="Conversion Rates"
      class="mb-8"
    >
      <rj-surveys-conversion-chart
        v-if="surveysLoaded"
        :days="surveysByDay"
      />
      <div v-else>
        Loading...
      </div>
    </vx-card>

    <vx-card
      title="Reviews Captured"
      class="mb-8"
    >
      <rj-reviews-captured-chart
        v-if="reviewsLoaded"
        :days="reviewsByDay"
      />
      <div v-else>
        Loading...
      </div>
    </vx-card>

    <vx-card
      title="Notifications Sent"
      class="mb-8"
    >
      <rj-notifications-sent-chart
        v-if="notificationsLoaded"
        :days="notificationsByDay"
      />
      <div v-else>
        Loading...
      </div>
    </vx-card>

    <vx-card
      title="RevenueJump API Errors"
      class="mb-8"
    >
      <rj-api-errors-chart
        v-if="rjApiErrorsLoaded"
        :rj-api-errors="rjApiErrors"
      />
      <div v-else>
        Loading...
      </div>
    </vx-card>

    <vx-card
      title="Integration Errors"
      class="mb-8"
    >
      <rj-integration-errors-chart
        v-if="integrationsLoaded"
        :integration-errors="integrationErrors"
      />
      <div v-else>
        Loading...
      </div>
    </vx-card>
  </section>
</template>

<script>
import { sub, set } from 'date-fns';
import RjSurveysSentChart from '@/views/admin-dashboard/charts/RjSurveysSentChart.vue';
import RjSurveysConversionChart from '@/views/admin-dashboard/charts/RjSurveysConversionChart.vue';
import RjReviewsCapturedChart from '@/views/admin-dashboard/charts/RjReviewsCapturedChart.vue';
import RjNotificationsSentChart from '@/views/admin-dashboard/charts/RjNotificationsSentChart.vue';
import RjIntegrationErrorsChart from '@/views/admin-dashboard/charts/RjIntegrationErrorsChart.vue';
import RjApiErrorsChart from '@/views/admin-dashboard/charts/RjApiErrorsChart.vue';


export default {
  name: 'RjAdminReports',
  components: {
    RjSurveysSentChart, RjSurveysConversionChart, RjReviewsCapturedChart, RjNotificationsSentChart, RjIntegrationErrorsChart, RjApiErrorsChart,
  },
  data() {
    return {
      surveysLoaded: false,
      reviewsLoaded: false,
      notificationsLoaded: false,
      rjApiErrorsLoaded: false,
      integrationsLoaded: false,
      surveysByDay: [],
      reviewsByDay: [],
      notificationsByDay: [],
      rjApiErrors: [],
      integrationErrors: {},
    };
  },
  mounted() {
    this.fetchSurveysForWeek();
    this.fetchReviewsForWeek();
    this.fetchNotificationsForWeek();
    this.fetchIntegrationErrors();
    this.fetchRjApiErrors();
  },
  methods: {
    async fetchSurveysForWeek() {
      const fetches = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = sub(today, { days: i });
        fetches.push(this.fetchSurveysForDate(date));
      }
      this.surveysByDay = await Promise.all(fetches);
      this.surveysLoaded = true;
    },
    async fetchSurveysForDate(date) {
      const startDate = set(date, { hours: 0, minutes: 0, seconds: 0 });
      const endDate = set(date, { hours: 23, minutes: 59, seconds: 59 });
      const surveys = await this.$store.dispatch('surveys/getSurveys', {
        where: { createdAt: { between: [startDate, endDate] } },
        include: [
          {
            relation: 'surveyMessages',
            scope: {
              where: {
                createdAt: { between: [startDate, endDate] },
              },
              fields: ['type', 'createdAt'],
            },
          },
        ],
      });

      return surveys.reduce((datasets, survey) => {
        const ds = {
          ...datasets,
        };
        ds.surveysCount++;
        if (survey.surveyMessages.some((message) => message.type === 'Text')) {
          ds.smsCount++;
        }
        if (survey.surveyMessages.some((message) => message.type === 'Email')) {
          ds.emailCount++;
        }
        if (survey.pageVisited) {
          ds.visitedCount++;
        }
        if (['Completed', 'Feedback', 'Review site', 'Shared'].includes(survey.status)) {
          ds.completedCount++;
        }
        return ds;
      }, {
        date, surveysCount: 0, smsCount: 0, emailCount: 0, visitedCount: 0, completedCount: 0,
      });
    },
    async fetchReviewsForWeek() {
      const fetches = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = sub(today, { days: i });
        fetches.push(this.fetchReviewsForDate(date));
      }
      this.reviewsByDay = await Promise.all(fetches);
      this.reviewsLoaded = true;
    },
    async fetchReviewsForDate(date) {
      const startDate = set(date, { hours: 0, minutes: 0, seconds: 0 });
      const endDate = set(date, { hours: 23, minutes: 59, seconds: 59 });
      const reviews = await this.$store.dispatch('reviews/getReviews', {
        where: { createdAt: { between: [startDate, endDate] } },
        fields: ['createdAt'],
      });
      return {
        date,
        reviewsCount: reviews.length,
      };
    },
    async fetchNotificationsForWeek() {
      const fetches = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = sub(today, { days: i });
        fetches.push(this.fetchNotificationsForDate(date));
      }
      this.notificationsByDay = await Promise.all(fetches);
      this.notificationsLoaded = true;
    },
    async fetchNotificationsForDate(date) {
      const startDate = set(date, { hours: 0, minutes: 0, seconds: 0 });
      const endDate = set(date, { hours: 23, minutes: 59, seconds: 59 });
      const notifications = await this.$store.dispatch('notifications/getNotifications', {
        where: { createdAt: { between: [startDate, endDate] } },
        fields: ['createdAt'],
      });
      return {
        date,
        notificationsCount: notifications.length,
      };
    },
    async fetchRjApiErrors() {
      const { errors } = await this.$store.dispatch('notifications/getRjApiErrors');
      this.rjApiErrors = errors;
      this.rjApiErrorsLoaded = true;
    },
    async fetchIntegrationErrors() {
      const { errors } = await this.$store.dispatch('notifications/getIntegrationErrors');
      this.integrationErrors = errors;
      this.integrationsLoaded = true;
    },
  },
};
</script>
