<template>
  <section>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600 mr-3 border-0 md:border-r border-solid border-gray-500">
        {{ partner.name }}
      </h2>
      <rj-breadcrumb :items="breadcrumbs" />
    </header>

    <vs-tabs
      :value="currentTab"
      :position="isSmallerScreen ? 'top' : 'left'"
      class="tabs-shadow-none"
    >
      <vs-tab
        v-bind="tabProps({ slug: 'general', icon: 'icon-box' })"
        @click="navigate('general')"
      >
        <rj-general-tab v-bind="{ partner }" />
      </vs-tab>

      <vs-tab
        v-bind="tabProps({ slug: 'users', icon: 'icon-users' })"
        @click="navigate('users')"
      >
        <rj-users-tab v-bind="{ partner }" />
      </vs-tab>

      <vs-tab
        v-bind="tabProps({ slug: 'accounting', icon: 'icon-dollar-sign' })"
        @click="navigate('accounting')"
      >
        <rj-accounting-tab v-bind="{ partner }" />
      </vs-tab>
    </vs-tabs>
    <template v-if="psp_show">
      <component
        :is="psp_modal"
        :props="psp_props"
      />
    </template>
  </section>
</template>

<script>
import RjUsersTab from '@/views/admin-dashboard/partners/tabs/RjUsersTab.vue';
import RjGeneralTab from '@/views/admin-dashboard/partners/tabs/RjGeneralTab.vue';
import RjAccountingTab from '@/views/admin-dashboard/partners/tabs/RjAccountingTab.vue';
import PartnerServiceProvider from '@/views/partner-dashboard/PartnerServiceProvider';

export default {
  name: 'RjPartner',

  components: {
    RjUsersTab,
    RjGeneralTab,
    RjAccountingTab,
  },

  mixins: [PartnerServiceProvider],

  props: {
    slug: {
      type: [String, null],
      required: true,
    },

    partner: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tabs: {
        general: 'General',
        users: 'Users',
        accounting: 'Accounting',
      },
    };
  },

  computed: {
    title() {
      return this.tabs[this.slug];
    },

    currentTab() {
      return Object.keys(this.tabs).indexOf(this.slug);
    },

    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },

    breadcrumbs() {
      const { id, name } = this.partner;

      return [
        { title: 'Partners', url: '/admin/partners' },
        { title: name, url: `/admin/partners/${id}/general` },
        { title: this.title, active: true },
      ];
    },
  },

  mounted() {
    this.navigate(this.slug);
  },

  methods: {
    tabProps({ slug, icon }) {
      return {
        icon,
        iconPack: 'feather',
        label: !this.isSmallerScreen ? this.tabs[slug] : '',
      };
    },

    navigate(slug) {
      if (this.slug !== slug) {
        this.$router.push({ name: 'admin-partner', params: { slug } });
      }
    },
  },
};
</script>
