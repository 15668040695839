<template>
  <vx-card title="Partners">
    <template slot="no-body">
      <div class="pt-6 mx-6">
        <vs-table
          search
          pagination
          :data="partners"
          :max-items="itemsPerPage"
          :no-data-text="'No Partners'"
          @selected.stop=""
        >
          <template slot="header">
            <div class="flex flex-wrap-reverse items-center flex-grow justify-between">
              <!-- ADD NEW BUTTON -->
              <div class="mt-auto">
                <vs-button
                  color="primary"
                  class="w-full"
                  @click.stop="addPartner"
                >
                  Add Partner
                </vs-button>
              </div>
            </div>
          </template>

          <template slot="thead">
            <vs-th>PARTNER</vs-th>
            <vs-th>
              <span class="w-full text-center">COMPANIES</span>
            </vs-th>
            <vs-th>
              <span class="w-full text-center">LOCATIONS</span>
            </vs-th>
            <vs-th>
              <span class="w-full text-center">PLAN</span>
            </vs-th>
            <vs-th>
              <span class="w-full text-center">ACTION</span>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr
                v-for="(partner, index) in data"
                :key="index"
                :data="partner"
              >
                <vs-td
                  class="whitespace-no-wrap overflow-hidden"
                  style="max-width: 0.063rem;"
                >
                  <p class="font-medium truncate">
                    {{ partner.name }}
                  </p>
                </vs-td>

                <vs-td class="whitespace-no-wrap overflow-hidden">
                  <p class="font-medium truncate text-center">
                    {{ partner.companies.length }}
                  </p>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <p class="font-medium text-center">
                    {{ totalLocations(partner.companies) }}
                  </p>
                </vs-td>

                <vs-td
                  class="whitespace-no-wrap overflow-hidden"
                  style="max-width: 0.063rem;"
                >
                  <p class="font-medium truncate text-center">
                    {{ partner.partnerPlan.name }}
                  </p>
                </vs-td>

                <vs-td class="w-2/12 whitespace-no-wrap">
                  <div class="flex flex-no-wrap">
                    <vs-button
                      icon-pack="feather"
                      icon="icon-settings"
                      type="flat"
                      class="inline-block"
                      title="Partner Settings"
                      @click.stop="jumpToPartner(partner)"
                    />
                    <vs-button
                      icon-pack="feather"
                      icon="icon-arrow-up"
                      type="flat"
                      class="inline-block"
                      title="Upgrade Plan"
                      @click.stop="openPlanUpgradeDialog(partner)"
                    />
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </template>
  </vx-card>
</template>

<script>
export default {
  name: 'RjPartnersTable',

  props: {
    partners: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      itemsPerPage: 5,
    };
  },

  methods: {
    totalLocations(companies) {
      return companies.reduce((acc, company) => {
        const total = acc + company.locations.length;
        return total;
      }, 0);
    },

    jumpToPartner(partner) {
      this.$router.push({
        name: 'admin-partner',
        params: { partner: partner.id, slug: 'general' },
      });
    },
  },
};
</script>
